.webform-button--submit {
  line-height: 1;
  background-color: transparent;
  font-weight: 500;
  padding: 0;
  margin: 0;
  border: 0;
  display: inline-block;
  text-align: center;
  transition: all 250ms ease;
  padding: 5px 15px;
  position: relative;
  font-size: 1rem;
}
.webform-button--submit:hover, .webform-button--submit:focus {
  text-decoration: none;
}
.webform-button--submit:hover {
  cursor: pointer;
}
.webform-button--submit:last-child {
  margin-right: 0;
}

fieldset.captcha {
  border: 0;
  background: none;
  padding: 0;
}
fieldset.captcha legend {
  display: none;
}
fieldset.captcha .captcha__description {
  font-size: 0.65rem;
}