//==============================================================================
// BUTTON MIXINS
//==============================================================================

//-----------------------------
// Common button styles
//-----------------------------

@mixin btn {
  line-height: 1;
  background-color: transparent;
  font-weight: $btn-fw;
  padding: 0;
  margin: 0;
  border: 0;
  display: inline-block;
  text-align: center;
  @include transition();

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &:hover {
    cursor: pointer;
  }
}

@mixin btn--default {
  padding: 5px 15px;
  position: relative;
  font-size: 1rem;

  &:last-child {
    margin-right: 0;
  }
}


//-----------------------------
// SVG button styles
//-----------------------------

// SVG Positions: no-text|hard-left|inline-left|hard-right|inline-right
@mixin btn--svg($svg_pos: 'no-text') {
  svg {
    @include transition;
    height: 16px;
    max-width: 16px;
    vertical-align: middle;
  }

  @if $svg_pos == 'no-text' {
    svg {
      width: 18px;
    }
  } @else {

    @if $svg_pos == 'inline-left' or $svg_pos == 'inline-right' {
      line-height: 22px;
    }

    svg {
      @if $svg_pos == 'hard-left' or $svg_pos == 'hard-right' {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      @if $svg_pos == 'hard-left' {
        left: 14px;

        & + span {
          margin-left: 27px;
        }
      }

      @if $svg_pos == 'hard-right' {
        right: 14px;

        & + span {
          margin-right: 27px;
        }
      }

      @if $svg_pos == 'inline-left' or $svg_pos == 'inline-right' {
        position: relative;
        //bottom: 2px;
      }

      @if $svg_pos == 'inline-left' {
        margin-right: 10px;
      }

      @if $svg_pos == 'inline-right' {
        margin-left: 10px;
      }
    }

  }
}


//-----------------------------
// Button double-border mixin
//-----------------------------

@mixin btn--double-border($colors, $settings) {
  color: map-get($colors, txt);
  border: map-get($settings, def-bw) solid map-get($colors, border);
  background-color: map-get($colors, bg);
  outline: map-get($settings, def-olw) solid map-get($colors, outline);
  text-transform: map-get($settings, text-trans);

  span {
    vertical-align: middle;
  }

  svg {
    fill: map-get($colors, icon);
  }

  @include mq($bp-md-min) {
    padding-top: map-get($settings, md-pad-top);
    padding-bottom: map-get($settings, md-pad-bot);
    border-width: map-get($settings, md-bw);
    outline-width: map-get($settings, md-olw);

    span {
      padding-right: 15px;
    }
  }

  &:hover,
  &:focus {
    color: map-get($colors, txt-hov);
    border-color: map-get($colors, border-hov);
    outline: map-get($settings, def-olw) solid map-get($colors, outline-hov);
    background-color: map-get($colors, bg-hov);

    svg {
      fill: map-get($colors, icon-hov);
    }

    @include mq($bp-md-min) {
      outline: map-get($settings, md-olw) solid map-get($colors, outline-hov);
    }
  }
}


//-----------------------------
// Button color mixins
//-----------------------------

@mixin btn--color($colors) {
  color: map-get($colors, txt);
  border: 1px solid map-get($colors, border);
  background-color: map-get($colors, bg);

  svg {
    fill: map-get($colors, icon);
  }

  &:hover,
  &:focus {
    color: map-get($colors, txt-hov);
    border-color: map-get($colors, border-hov);
    background-color: map-get($colors, bg-hov);

    svg {
      fill: map-get($colors, icon-hov);
    }
  }
}

@mixin btn--brand() {
  @include btn--color($c-btn-default);
}
