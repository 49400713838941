@import "./_sass-essentials/essentials.scss";

.webform-button--submit {
  @include btn;
  @include btn--default;
}

fieldset {
  &.captcha {
    border: 0;
    background: none;
    padding: 0;

    legend {
      display: none;
    }

    .captcha__description {
      font-size: .65rem;
    }
  }
}
